/**
*
*   Home Categories
*       - The 3 main categories on the home page
*
**/
'use client';


import 'aos/dist/aos.css';
import Config from '@/config';
import Button from '@/components/button';
import Image from '@/components/image';
import { Link } from '@/components/link';
import { useState, useLayoutEffect, useRef } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { useTranslations } from 'next-intl';
//import 'swiper/css';

//Build the Element
export default function Categories({ title=false, settings={}, data=[], ...props }){

    //Use Translations
    const t = useTranslations();

    //The Reference
    const ref = useRef();

    //Set the Point State
    const [point, setPoint] = useState({ state: false });

    //Swiper
    const [swiper, setSwiper] = useState({
        instance:   null,
        first:      true,
        last:       false
    });

    //Paramenters
    const Parameters = Object.assign({

            //Swiper Settings
            spaceBetween:   10,
            breakpoints:    {
                0: {
                    slidesPerView: 'auto'
                },
                800: {
                    slidesPerView: 3
                }
            },

            onSwiper: swiper => setSwiper({
                instance:       swiper,
                first:          swiper.isBeginning,
                last:           swiper.isEnd
            }),

            onSlideChange: swiper => setSwiper({
                instance:       swiper,
                first:          swiper.isBeginning,
                last:           swiper.isEnd
            }),

            onTransitionEnd: swiper => setSwiper({
                instance:       swiper,
                first:          swiper.isBeginning,
                last:           swiper.isEnd
            }),

            onResize: swiper => setSwiper({
                instance:       swiper,
                first:          swiper.isBeginning,
                last:           swiper.isEnd
            })

        },settings);




    /**
    *
    *   Resize the nav to the center of the categories
    *
    **/
    useLayoutEffect( () => {

        /**
        *
        *  RESIZER
        *   - The Resizer Function to align the nav to the center of the image
        *
        *   Params:
        *       n/a
        **/
        let obj = {
            resizer: (e) => {

                let $container  = ref.current,
                    $nav        = $container.querySelector('nav.controls'),
                    height      = $container.querySelectorAll('img')[0].offsetHeight;

                $nav.style.top = ( ( height / 2 ) + ( $container.querySelector('header')?.offsetHeight || 0 ) ) + 'px';

            }
        }

        //Add the resizer event
        window.addEventListener('resize', obj.resizer );

        //Dispatch the event to start it
        obj.resizer();

        //Cleanup
        return () => window.removeEventListener('resize', obj.resizer );

    },[point]);

    //Return
    return (
        <section id="categories" className="container" ref={ ref }>
            { title && (
                <header>
                    <h2>{ title }</h2>
                </header>
            )}
            <Swiper { ...Parameters } className="list">{
                data.map( (row , i) => {
                    return (
                        <SwiperSlide className={ 'category' + ( row.subnav && row.subnav.links.length > 0 ? ' subnav' : '' ) } key={i}>
                            { ( !row.subnav || row.subnav.links.length == 0 ) && (
                                <>
                                    <Image src={ row.image } width="432" height="500" alt={ row.title } />
                                    <div className="content">
                                        <h2>{ row.title }</h2>
                                        <p>{ row.description }</p>
                                        <Button href={ row.link } type="dark" inverted>{ ( row.button ? row.button : t('Navigation.shop-now') ) }</Button>
                                    </div>
                                </>
                            )}
                            { ( row.subnav && row.subnav.links.length > 0 ) && (
                                <>
                                    <div className="wrap">
                                        <Image src={ row.image } width="432" height="500" alt={ row.title } />
                                        <div className="bg">
                                            <div className="content">
                                                <h2>{ row.title }</h2>
                                                <p>{ row.description }</p>
                                                <Button href={ row.link } type="white" inverted>{ ( row.button ? row.button : t('Navigation.shop-now') ) }</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <nav>
                                        <header>
                                            <h3>{ row.subnav.title }</h3>
                                        </header>
                                        <ul>
                                            { row.subnav.links.map( (item,i) => (
                                                <li key={ i }>
                                                    <Link href={ item.link }>{ item.title }</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </>
                            )}
                        </SwiperSlide>
                    )
                })
            }</Swiper>
            <nav className="controls">
                <Button onClick={ () => swiper.instance.slidePrev() } disabled={ swiper.first && !swiper?.instance?.params?.loop } hidden={ swiper.first && !swiper?.instance?.params?.loop }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                        <path d="M10.6317 1.81006L18.4803 9.65863L10.6317 17.5072" stroke="white" strokeWidth="1.85526" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M18.4802 9.65869L1.78296 9.65869" stroke="white" strokeWidth="1.85526" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </Button>
                <Button onClick={ () => swiper.instance.slideNext() } disabled={ swiper.last && !swiper?.instance?.params?.loop } hidden={ swiper.last && !swiper?.instance?.params?.loop }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                        <path d="M10.6317 1.81006L18.4803 9.65863L10.6317 17.5072" stroke="white" strokeWidth="1.85526" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M18.4802 9.65869L1.78296 9.65869" stroke="white" strokeWidth="1.85526" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </Button>
            </nav>
        </section>
    );

}