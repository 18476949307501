/**
*
*   Search Button
*       - The Button Control for the Search in the Nav
*
**/
'use client';

import Config from '@/config';
import { getPath } from '@/components/image';
import Image from '@/components/image';
import Player from '@vimeo/player';
import { useState, useEffect, useRef } from 'react';
import { useLocale, useTranslations } from 'next-intl';


//Build the Element
export default function Banner({ children, image, video, tag='header', gradient=false, base=false, fullpage, parallax, ...props }){

    const Tag = tag;

    //
    const classes = ['banner'];

    //If it's a full page banner
    if( fullpage ) classes.push('fullpage');
    
    //The current video
    const $video = useRef(null);

/*
    //Current Video
    const [ video , setVideo ] = useState(false);


    //The Video
    useEffect(()=>{
        if( videos ){

            //Prepare the videos for queries
            const media = Object.keys( videos ).filter( key => !isNaN(key) ).sort( (a,b) => a - b );

            //On Resize, find the closest video
            const Resizer = () => {

                //Get States
                const size = window.innerWidth;
                const current = media.filter( key => key >= size );
                const $el = $video.current.querySelector('video');
                const time = ( $el ? $el.currentTime : 0 );

                if( $el ) $el.remove();

                //Get the video
                const video = ( current.length > 0 ? videos[ current[0] ] : videos[ media[ media.length - 1 ] ] );

                //Prepare the Player
                const player = Object.assign( document.createElement('video') , {
                    poster:         getPath( video.replace( '.webm' , '.jpg' ) , base ),
                    playsInline:    true,
                    autoplay:       true,
                    muted:          true,
                    loop:           true
                });

                player.appendChild( 
                    Object.assign( document.createElement('source'), {
                        src:    getPath( video , base ),
                        type:   'video/webm'
                    }) 
                );

                player.onload = () => {
                    player.play();
                }

                $video.current.appendChild(player);

            };

            //Add the Event Listener
            window.addEventListener( 'resize' , Resizer );

            //Get the Current Video
            Resizer();

            //Cleanup
            return () => window.removeEventListener( 'resize' , Resizer );

        }
    },[]);
*/


    useEffect(()=>{
        if( $video.current ){

            const player = new Player( $video.current , {
                badge:      0,
                background: 1,
                controls:   0,
                autoplay:   1,
                loop:       1,
                autopause:  0,
                id:         video
            });

            player.ready().then( () => $video.current.classList.add('ready') )

        }
    },[ $video , video ]);

    //Parallax Banner
    if( image && parallax )
        return (
            <Tag role="banner" className={ [ ...classes, 'parallax' ].join(' ') } {...props}>
                <div className="wrap">
                    <div className="container">
                        { children }
                    </div>
                </div>
                <div className="bg">
                    <Image src={ image } base={ base } alt="" placeholder="blur" blurDataURL={image} fill />
                    <div>
                        <div style={{ backgroundImage: 'url(' + getPath(image, base) + ')' }}></div>
                    </div>
                </div>
            </Tag>
        );

    //Standard Banner
    if( image )
        return (
            <Tag role="banner" className={ classes.join(' ') } {...props}>
                <div className="bg">
                    <div className="container">
                        { children }
                    </div>
                </div>
                <Image src={image} base={ base } alt="" placeholder="blur" blurDataURL={image} fill />
            </Tag>
        );

    if( video )
        return (
            <Tag role="banner" className={ classes.join(' ') } {...props}>
                <div className="bg">
                    <div className="container">
                        { children }
                    </div>
                </div>
                <div className="video">
                    <div className="wrapper" ref={ $video }>
                        {/* Player loads here */}
                    </div>
                    <Image className="poster" src={ `https://vumbnail.com/${ video }.jpg` } alt="" placeholder="blur" blurDataURL={ `https://vumbnail.com/${ video }.jpg`  } fill />
                </div>
            </Tag>
        );

    //Basic Banner
    return (
        <Tag role="banner" className={ [ ...classes, 'empty' ].join(' ') } {...props}>
            <div className="container">
                { children }
            </div>
        </Tag>
    )

}