/**
*
*   Testimonials
*       - A testimonial / content block
*
**/
'use client';

import Config from '@/config';
import Image from '@/components/image';
import Button from '@/components/button';
import Player from '@vimeo/player';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useState, useEffect, useRef } from 'react';
import { useTranslations } from 'next-intl';

//Load Module
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

//Build the Element
export default function Testimonials({ title, content, testimonials=[], ...props }){

    //Get the Translations
    const t = useTranslations();
    const ref = useRef();
    const [ testimonial , setTestimonial ] = useState( testimonials?.[0] );

    const Testimonials = {
        index:  testimonials.findIndex( row => row == testimonial ),
        size:   testimonials.length,
        next:   () => {

            let $container  = ref.current;
            let $quote      = $container.querySelector('blockquote');
            let $video      = $container.querySelector('.video');

            gsap.to($quote,{
                yPercent: -20,
                autoAlpha: 0
            })

            gsap.to($video,{
                yPercent: 20,
                autoAlpha: 0,
                onComplete: () => {

                    const current = ( Testimonials.index + 1 == Testimonials.size ) ? testimonials[0] : testimonials[ Testimonials.index + 1 ] ;
                    //Set the Video
                    setTestimonial( current );

                    //Pause the video (if playing)
                    $video.player.pause().then(()=>{

                        //Go to next video
                        $video.player.loadVideo( current.video ).then(()=>{

                            //Load in
                            gsap.to( [ $quote , $video ] , {
                                yPercent: 0,
                                autoAlpha: 1
                            });

                        }).catch(e => console.warn('Video not found:', e) )
                    
                    });

                }
            });

        }
    }

    //Use Effect
    useEffect(()=>{

        //Get the iframe
        const $container = ref.current;
        const $video = $container.querySelector('.video');

        //Skip if empty content
        if(!$video) return;

        //Assign the player
        $video.player = new Player($video,{
            badge: 0,
            autopause: 0,
            portrait: 0,
            byline: 0,
            id: testimonials[0].video
        });

        //Toggle play state
        $video.player.on( 'play' , () => $container.classList.add('playing') );
        $video.player.on( 'pause' , () => $container.classList.remove('playing') );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ ref.current ])

    //On Mount
    useGSAP(() => {

        //Get the Container
        let $container  = ref.current,
            animations  = [{
                timeline:   gsap.timeline(),
                $el:        $container.querySelector('blockquote'),
                from:       { yPercent: -5 },
                to:         { yPercent: 5 }
            },{
                timeline:   gsap.timeline(),
                $el:        $container.querySelector('iframe'),
                from:       { yPercent: 5 },
                to:         { yPercent: -5 }
            }];

        animations.forEach( ({ timeline , $el , from , to }) => {

            //Setup Animation
            timeline.fromTo( $el , from , to );

            //The Scroll Trigger
            ScrollTrigger.create({
                animation:              timeline,
                trigger:                $container,
                invalidateOnRefresh:    true,
                //markers:                false,
                scrub:                  0.5,
                start:                  'top center',
                end:                    () => "+=" + $container.offsetHeight
            });

        });

    }, { scope: ref });


    //Return
    return (
        <section id="testimonials" ref={ ref } className="container">
            
            { testimonials.length > 0 && (
                <div>
                    <blockquote>
                        <div>
                            <header>
                                { [...Array(5)].map( (review,i) => (
                                    <svg key={i} xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                                        <path d="M7.34073 0L9.0635 5.10575L14.4517 5.16644L10.1282 8.38266L11.7356 13.5259L7.34073 10.4079L2.94589 13.5259L4.55322 8.38266L0.229733 5.16644L5.61795 5.10575L7.34073 0Z" fill={ ( testimonial.rating >= i ? 'white' : '#C3C6A7' ) } />
                                    </svg>
                                ))}
                            </header>
                            <q>{ testimonial.quote }</q>
                            <cite>{ testimonial.name }, { testimonial.location }</cite>
                        </div>
                    </blockquote>
                    <div className="video">

                    </div>
                </div>
            )}
            <div>
                <h2>{ title }</h2>
                { content }
                { testimonials.length > 0 && (
                    <nav>
                        { t('Pages.Home.testimonials.page',{ 
                            current:    Testimonials.index + 1 , 
                            total:      Testimonials.size
                        })}
                        <Button onClick={ () => Testimonials.next() } type="dark" inverted arrow>
                            { t('Pages.Home.testimonials.next') }
                        </Button>
                    </nav>
                )}
            </div>
        </section>
    );

}